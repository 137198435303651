<template>
  <div class="home">
    <div>
      <img src="../../assets/img/old/logo.png"
           class="logo">
      <div class="title">欢迎使用老年人中医健康管理服务</div>
    </div>
    <div class="c">
      <img class="imgInfo"
           src="../../assets/img/old/index-mark.png">
      <div class="cc">
        <input class="input"
               type="text"
               placeholder="请输入体检编号"
               v-model="code">
        <button class="btn"
                @click="startAction">开始服务</button>
      </div>

    </div>
    <div class="mark">爱家医PHR云平台提供技术支持</div>
  </div>
</template>
<script>
import { apiUrl, http } from "../../config";
export default {
  name: '',
  data () {
    return {
      code: '',
      query: {}
    };
  },
  methods: {
    startAction () {
      if (this.code.length <= 0) {
        this.$toasted.show('请输入请输入体检编号');
        return;
      }
      let url = `/ophs/questionnaire/validate`
      this.query = { ...this.$route.query, ...{ olderReportCode: this.code } }
      // this.query = { applyid: "NwmuHOtod9btK9F7WZmuMEOi3bJcFCxP", idcard: "130406198703210334", olderReportCode: this.code }
      http.get(url, { params: this.query }).then(res => {
        if (res.code == 200) {
          this.goToTest()
        } else {
          this.$toasted.show(res.msg);
        }
      });
    },
    goToTest () {
      this.$router.push({
        path: "/oldman/questions",
        query: { ...this.query }
      });
    }
  }
}
</script>

<style lang='scss' scoped>
.home {
  width: 7.5rem;
  margin: 0 auto;
  // position: absolute;
  // height: 100%;
  min-height: 100%;
  background: rgba(0, 198, 174, 1);
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .logo {
    width: 0.76rem;
    height: 0.66rem;
    margin-top: 1.66rem;
  }
  .title {
    margin: 0.2rem auto 0 auto;
    text-align: center;
    width: 4.63rem;
    height: 1.32rem;
    font-size: 0.42rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    line-height: 0.66rem;
  }
  .c {
    // flex: 1;
    margin: 0.68rem auto 0 auto;
    width: 6.26rem;
    height: 6.7rem;
    background: rgba(255, 255, 255, 1);
    border-radius: 0.14rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .imgInfo {
      width: 3.14rem;
      height: 2.7rem;
      margin: 0.32rem;
    }
    .cc {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      margin-bottom: 0.8rem;
      .input {
        width: 4.54rem;
        height: 0.8rem;
        background: rgba(0, 198, 174, 0.1);
        border-radius: 0.38rem;
        outline-style: none;
        border: 0px;
        font-size: 0.28rem;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        padding: 0 0.4rem;
        // padding-left: 0.4rem;
      }
      input:focus {
        border-color: #66afe9;
        outline: 0;
        -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
          0 0 8px rgba(102, 175, 233, 0.6);
      }
      .btn {
        outline-style: none;
        width: 5.34rem;
        height: 0.8rem;
        background: rgba(0, 198, 174, 1);
        border-radius: 0.38rem;
        font-size: 0.36rem;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: rgba(255, 255, 255, 1);
      }
    }
  }
  .mark {
    font-size: 0.26rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 1);
    margin-top: 1.26rem;
    // position: absolute;
    // bottom: 0;
    width: 100%;
  }
}
</style>
